/* You can add global styles to this file, and also import other style files */

/* @import '~@swimlane/ngx-datatable/index.css';
@import '~@swimlane/ngx-datatable/themes/material.scss';
@import '~@swimlane/ngx-datatable/themes/dark.scss';
@import '~@swimlane/ngx-datatable/themes/bootstrap.scss';
@import '~@swimlane/ngx-datatable/assets/icons.css'; */

@import "~@ng-select/ng-select/themes/default.theme.css";
@import "~@ng-select/ng-select/themes/material.theme.css";
@font-face {
  font-family: "Poppins Medium";
  src: url(assets/poppins/Poppins-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins SemiBold";
  src: url(assets/poppins/Poppins-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins Regular";
  src: url(assets/poppins/Poppins-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins Light";
  src: url(assets/poppins/Poppins-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins Bold";
  src: url(assets/poppins/Poppins-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat";
  src: url(assets/montserrat/Montserrat-Regular.ttf) format("truetype");
}

.top {
  margin-top: 4rem;
  /* margin-top: 6%; */
}

.breadcrumb li a {
  text-align: left;
  font: normal normal 300 16px/22px Poppins;
  letter-spacing: 0px;
  color: #979797;
  opacity: 1;
}

.backgroundbox {
  background: #e9ecef 0% 0% no-repeat padding-box;
  border: 1px solid #e6e6e6;
  opacity: 1;
  height: 25px;
  width: 100%;
}

a.btn_1,
.btn_1 {
  border: none;
  color: #fff;
  background: #6c63ff 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 20px #00000029;
  border-radius: 25px;
  outline: none;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  padding: 15px 30px;
  text-align: left;
  /* font: normal normal 600 14px/21px Poppins; */
  font-size: 14px !important;
  font-family: Poppins SemiBold !important;
  line-height: 2;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: uppercase;
  line-height: 1;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
}

a.btn_1:hover,
.btn_1:hover {
  background: #0054a6;
  color: #fff;
}

a.btn_1.full-width,
.btn_1.full-width {
  display: block;
  width: 100%;
  text-align: center;
  margin-bottom: 5px;
}

a.btn_1.medium,
.btn_1.medium {
  font-size: 16px;
  font-size: 1rem;
  padding: 18px 40px;
}

a.btn_1.rounded,
.btn_1.rounded {
  -webkit-border-radius: 25px !important;
  -moz-border-radius: 25px !important;
  -ms-border-radius: 25px !important;
  border-radius: 25px !important;
}

a.btn_1.outline,
.btn_1.outline {
  border: 2px solid #3f9fff;
  color: #3f9fff;
  padding: 11px 40px;
  background: none;
}

a.btn_1.outline:hover,
.btn_1.outline:hover {
  background: #0054a6;
  color: #fff;
  border-color: #0054a6;
}

a.btn_explore {
  background: #fff;
  display: block;
  width: 60px;
  height: 60px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  line-height: 65px;
  text-align: center;
  position: absolute;
  bottom: 40px;
  left: 50%;
  margin-left: -30px;
  font-size: 24px;
  font-size: 1.5rem;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

a.btn_explore:hover {
  bottom: 30px;
}

.divider {
  text-align: center;
  height: 1px;
  margin: 30px 0 15px 0;
  background-color: #ededed;
}

.divider span {
  position: relative;
  top: -20px;
  /* background-color: #fff; */
  display: inline-block;
  padding: 10px;
  /* font-style: italic; */
}

ul.social_teacher {
  list-style: none;
  padding: 0;
  margin: 0 0 15px 0;
  font-size: 18px;
  font-size: 1.125rem;
  text-align: center;
}

ul.social_teacher li {
  display: inline-block;
  border: none;
  width: 40px;
  height: 40px;
  padding: 0;
  line-height: 45px;
  text-align: center;
}

.lineOr {
  text-align: left;
  font: normal normal normal 14px/21px Poppins;
  font-family: 14px;
  font-size: Poppins regular !important;
  letter-spacing: 0px;
  color: #979797;
  opacity: 1;
  background-color: #fff !important;
}

.bgbule {
  background: transparent
    radial-gradient(closest-side at 50% 50%, #21287f 0%, #111440 100%) 0% 0%
    no-repeat padding-box;
  opacity: 1;
  display: block;
  justify-content: center;
  align-items: center;
}

.brightness {
  filter: brightness(0.3);
}

.flexBlock {
  display: flex;
  /* height: 100vh; */
}

.width1 {
  width: 25%;
  padding: 3rem;
  min-width: 330px;
  background-color: #ffffff;
}

.width2 {
  width: 100%;
  height: 100%;
}

.text_slider {
  text-align: center;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin: 0 13rem;
}

.step-text1 {
  text-align: left;
  font-size: 20px;
  font-family: "Poppins SemiBold";
  font: normal normal medium 24px/34px Poppins;
  letter-spacing: 0px;
  color: #151515;
  opacity: 1;
}

.step-text2 {
  text-align: left;
  font: normal normal normal 14px/18px Poppins;
  letter-spacing: 0px;
  color: #979797;
  opacity: 1;
  margin-top: 8px;
}

.textb {
  text-align: center;
  /* font: normal normal medium 14px/21px Poppins; */
  font-size: 14px;
  font-family: "Poppins medium";
  letter-spacing: 0px;
  color: #6c63ff;
  opacity: 1;
}

.textC {
  text-align: center;
  font: normal normal 600 20px/30px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-top: 1rem;
}

.textD {
  text-align: center;
  font: normal normal normal 14px/22px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-top: 1rem;
  padding-left: 5rem;
  padding-right: 5rem;
  margin-bottom: 3rem;
}

.textcheck {
  text-align: left;
  font: normal normal normal 14px/22px Montserrat;
  letter-spacing: 0px;
  color: #979797;
  opacity: 1;
}

.styled-select,
.style-input {
  width: 100%;
  overflow: hidden;
  height: 44px;
  border: none;
  font-size: 14px !important;
  font-family: "poppins medium" !important;
  letter-spacing: 0px;
  color: #979797;
  border-radius: 0px !important;
  border-bottom: 2px solid #bfc0c1;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
}

.styled-select select {
  width: 100%;
  height: 43px;
  border: none;
  color: #495057c7;
  padding-right: 7px;
  padding-left: 7px;
}

:focus {
  outline: 0;
}

.carousel-indicators li {
  box-sizing: content-box;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 13px;
  height: 13px;
  border-radius: 19px;
  border: none;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

form {
  margin-top: 3rem;
}

figure {
  /* display: flex;
    justify-content: center;
    align-items: center; */
}

.carousel-indicators .active {
  width: 13px;
  height: 13px;
  margin: 0;
  background-color: #fff;
}

.lbl {
  font: normal normal medium 14px/21px Poppins;
  font-size: 14px !important;
  font-family: "Poppins medium" !important;
}

.fontHadColor {
  font-size: 16px;
  color: black;
}

@media only screen and (max-width: 991px) {
  .flexBlock {
    display: block;
  }
  .width1 {
    width: 100%;
  }
  .width2 {
    width: 100%;
  }
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.ngx-datatable .datatable-header {
  display: contents !important;
  overflow: hidden;
}

.ngx-datatable
  .datatable-header
  .datatable-header-cell
  .datatable-header-cell-template-wrap {
  height: inherit;
  font-family: "Poppins SemiBold";
  font-size: 14px;
  letter-spacing: 0px;
  color: #21287f !important;
}

.ngx-datatable .datatable-body-cell,
.ngx-datatable .datatable-header-cell {
  display: inline-block;
  line-height: 1.625;
  /* overflow-x: hidden; */
  vertical-align: top;
  border: 1px solid #d4d4d4;
  padding: 8px;
}

.ngx-datatable.fixed-row
  .datatable-scroll
  .datatable-body-row
  .datatable-body-cell,
.ngx-datatable.fixed-row
  .datatable-scroll
  .datatable-body-row
  .datatable-body-group-cell {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 16px !important;
  color: #979797;
  font-family: "Poppins Regular !important";
}

.poppins-medium {
  font-family: "Poppins Medium" !important;
}

.poppins-light {
  font-family: "Poppins Light" !important;
}

.poppins-Bold {
  font-family: "Poppins Bold" !important;
}

.poppinsregular {
  font-family: "Poppins Regular" !important;
}

.poppinsemibold {
  font-family: "Poppins SemiBold" !important;
}

.poppins-italic {
  font-family: "Poppins Italic";
}

.Montserrat-regular {
  font-family: "Montserrat";
}

.fontsize32 {
  font-size: 32px !important;
}

.fontsize16 {
  font-size: 16px;
}

.datatabel {
  font-size: 16px;
  color: #6c63ff;
}

.displayflex {
  display: flex;
}

.addbtn {
  margin-left: 1rem;
  color: #151515;
  cursor: pointer;
}

.addbtn:hover {
  color: #21287f;
}

.iconcolor {
  color: white !important;
}

.iconcolor:hover {
  color: wheat !important;
}

button:focus {
  outline: 0;
  outline: 0;
}

.activeheader {
  color: #6c63ff !important;
}

.universitydatainput {
  border: none;
  border-bottom: 2px solid #bfc0c1;
  width: 100%;
  margin-bottom: 1rem;
  padding: 5px 0px;
  font-family: "Poppins Medium";
  font-size: 16px;
  background: transparent;
  color: #979797 !important;
}

.icon-class {
  font-size: 19px !important;
  font-weight: 900;
  margin-left: 0.5rem;
  border: 1px solid #d8d8d8;
  cursor: pointer;
  color: #333333;
}

.icon-class:hover {
  color: #21287f;
}

.iconcolor {
  font-size: 20px;
  color: #6c63ff;
}

.cursorPointer {
  cursor: pointer;
}

.pencilicon {
  cursor: pointer;
  color: #6c63ff;
}

.iconbtn {
  border: none;
  background: none !important;
  font-size: 20px;
  color: black;
  border-radius: 15px;
}

.upload_btn:hover,
.iconbtn:hover,
.pencilicon:hover {
  color: #21287f;
  background: none !important;
}

button:focus {
  outline: 0;
}

.rating_top {
  margin-top: 0px;
}

@media only screen and (max-width: 991px) {
  .rating_top {
    margin-top: 8px;
  }
}

.addEditSaveBtn {
  display: flex;
  position: absolute;
  right: 20px;
}

.relative {
  position: relative;
}

.absoluteBackground {
  position: absolute;
  right: 9px;
  top: 25px;
  width: 30px;
  height: 30px;
  border-radius: 20px;
  background-color: white;
}

.collapse.show {
  display: block;
  /* height: 100vh !important; */
}

span.star.filled {
  color: #ffc107;
}

ngb-rating {
  color: #dddddd !important;
  font-size: 19px;
}

mat-icon {
  cursor: pointer;
}

.mat-upload-img {
  color: #f4f4f4;
  font-size: 58px;
  background: #090000c7;
  width: 58px !important;
  height: 58px !important;
  border-radius: 11px;
}

.margin1rem {
  margin: 1rem 0px;
}

/* #yearcal .mat-form-field-label,
#yearcal .mat-input-element {
  margin-left: 15px !important;
} */

#yearcal .mat-icon-button {
  font: inherit;
  vertical-align: baseline;
  color: black;
}

#yearcal input.mat-input-element {
  margin-top: -0.0625em;
  font-size: 16px;
  font-family: "Poppins Medium";
}

#yearcal .mat-form-field-underline {
  height: 2px;
}

#yearcal .mat-form-field-underline {
  background-color: rgb(191 192 193);
}

#yearcal .mat-form-field-appearance-legacy .mat-form-field-label {
  color: #727272;
  font-size: 16px;
  font-family: "Poppins Medium";
}

.mat-dialog-container {
  padding: 0px !important;
}

.mat-dialog-container {
  display: block;
  padding: 24px;
  border-radius: 4px;
  box-sizing: border-box;
  overflow: hidden !important;
  outline: 0;
  width: 100%;
  height: 100%;
  min-height: inherit;
  max-height: inherit;
}

.mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%),
    0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
  background: white !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.cdk-global-scrollblock {
  position: initial !important;
  width: 100%;
  overflow-y: visible !important;
}

.fontBlack {
  color: black !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

input[type="number"] {
  -moz-appearance: textfield;
}

#ngxdataTable .ngx-datatable.material .empty-row {
  font-family: "Poppins Medium";
}

#ngxdataTable .page-count {
  font-family: "Poppins SemiBold";
}

ly-img-cropper {
  background: #e8e8e8 !important;
}

.h {
  transform: translate3d(-430px, -208.5px, 0px) scale(0.554167);
  transform-origin: 971px 415.5px 0px;
  /* transform: translate3d(-430px, -208.5px, 0px) scale(0.554167)!important;
    transform-origin: 971px 415.5px 0px!important; */
}

.l > input {
  background: transparent;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer !important;
}

.LyImageCropper-area-q,
ly-cropper-area {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 1064px !important;
  height: 450px !important;
}

.ly-btn {
  background: black !important;
  opacity: 0.9 !important;
  color: #f1f1f1 !important;
  font-weight: bolder !important;
  border-radius: 0px !important;
}

.textlabel {
  font-family: "Poppins Medium";
  font-size: 16px;
  word-break: break-word;
  color: black;
}

.textdata {
  font-family: "Poppins Regular";
  font-size: 16px;
  color: #979797;
  word-break: break-word;
}

.textdatalink {
  font-family: "Poppins Regular";
  font-size: 16px;
  color: #979797;
  word-break: break-word;
  cursor: pointer;
}

.textdatalink:hover {
  color: #6c63ff !important;
}

.clubRelative {
  position: relative;
}

.clubAbsolute {
  position: absolute;
  right: 0;
  top: 0;
}

textarea {
  height: 64px !important;
}

.ng-select .ng-select-container {
  color: #6b6464e8;
  font-size: 16px !important;
  font-family: "Poppins Medium";
  border: none !important;
  background: #fbfbfb !important;
  border-radius: 0px;
}

.ng-placeholder {
  top: 1px;
  position: absolute;
  color: #6b6464e8;
  font-size: 10px !important;
  font-family: "Poppins Medium";
  transform-origin: bottom;
  transition: none;
}

.ng-select.ng-select-focused
  .ng-select-container
  .ng-value-container
  .ng-placeholder {
  transform: none !important;
  color: #6b6464e8 !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background: rgba(0, 0, 0, 0.04);
  color: #6b6464e8;
  font-size: 15px !important;
  font-family: "Poppins Medium";
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
  background: rgba(0, 0, 0, 0.12);
  color: #6b6464e8;
  font-size: 15px !important;
}

.ng-clear {
  display: none !important;
}

.ng-dropdown-panel.ng-select-bottom {
  top: calc(100% - 1.25em);
  box-shadow: none;
}

.ng-select .ng-select-container:after {
  border-bottom: 2px solid #bfc0c1;
  content: "";
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  transition: border-color 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
}

.ng-select.ng-select-focused .ng-select-container:after {
  border-color: #bfc0c1 !important;
  border-width: 2px;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  line-height: 3em;
  min-height: 3em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 16px;
  text-decoration: none;
  position: relative;
  color: #6b6464e8;
  font-size: 15px !important;
  font-family: "Poppins Medium";
  text-align: left;
}

.ng-option.ng-option-marked {
  color: #6b6464e8;
  font-size: 15px !important;
  font-family: "Poppins Medium";
}

.ng-select .ng-has-value .ng-placeholder,
.ng-select.ng-select-opened .ng-placeholder {
  transform: none !important;
}

.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  box-shadow: none;
}

:focus-visible {
  outline: 0;
}

.LyImageCropper-defaultContent-l > input {
  background: transparent;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer !important;
}

ngx-intl-tel-input input {
  height: 44px;
  margin-bottom: 20px;
  padding: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: #c7cace;
  border-radius: 4px;
  font-size: 18px;
}

ngx-intl-tel-input.ng-invalid.ng-touched input {
  border: 1px solid #c0392b;
}

ngx-intl-tel-input input:hover {
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.24);
}

ngx-intl-tel-input input:focus {
  outline: none !important;
  border-color: #3498db;
  box-shadow: 0 0 0 0 #000;
}

ngx-intl-tel-input input::-webkit-input-placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input:-ms-input-placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input::-ms-input-placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input::placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input[disabled] {
  background-color: #e5eaf1;
}

.iti--allow-dropdown input,
.iti--allow-dropdown input[type="text"],
.iti--allow-dropdown input[type="tel"],
.iti--separate-dial-code input,
.iti--separate-dial-code input[type="text"],
.iti--separate-dial-code input[type="tel"] {
  padding-right: 6px;
  padding-left: 52px;
  margin-left: 0;
  border: none;
  border-bottom: 2px solid #bfc0c1;
  width: 100%;
  margin-bottom: 1rem;
  font-family: "Poppins Medium";
  font-size: 16px;
  background: transparent;
  color: #979797 !important;
}

ngx-intl-tel-input.ng-invalid.ng-touched input {
  border: none;
  border-bottom: 2px solid #bfc0c1;
}

#backgroudwhite .ng-select-container.ng-has-value {
  border: none;
  background: white !important;
}

#ngselecetvalue .ng-select-container.ng-has-value {
  border: none;
  background: white !important;
}

#ngselecetvalue .ng-select .ng-select-container .ng-value-container {
  display: flex;
  flex: 1;
  margin-left: 0px !important;
}

h2 {
  font-size: 24px !important;
  color: #151515 !important;
  opacity: 1;
}

.border_shadow {
  margin-top: 1rem;
  background: white;
  padding: 2rem 20px 20px;
  box-shadow: 0px 0px 12px #00000014;
  border-radius: 7px;
  /* box-shadow: inset 0 -3em 3em rgb(0 0 0 / 0%), 0 0 0 2px rgb(255 255 255 / 0%), 0.1em 0.1em 1em rgb(0 0 0 / 38%); */
}

#inputWithIcon input[type="text"] {
  padding-left: 26px !important;
}

#inputWithIcon {
  position: relative;
}

#inputWithIcon mat-icon {
  position: absolute;
  left: 0;
  top: -2px;
  font-size: 17px;
  padding: 9px 0px;
  color: #979797;
  transition: 0.3s;
}

#inputWithIcon.inputIconBg mat-icon {
  color: #979797;
  padding: 9px 4px;
  border-radius: 4px 0 0 4px;
}

#inputWithIcon i {
  position: absolute;
  left: 0;
  top: -1px;
  padding: 9px 0px;
  color: #979797;
  transition: 0.3s;
}

#inputWithIcon.inputIconBg i {
  color: #979797;
  padding: 9px 4px;
  border-radius: 4px 0 0 4px;
}

.firstlatterCapital {
  text-transform: capitalize;
}

.editDescription {
  font-family: "Poppins Regular" !important;
  /* font-size: 16px; */
  font: normal normal normal 14px / 21px Poppins;
  background: transparent;
  color: #979797 !important;
}

p font {
  font-family: "Poppins Regular" !important;
  font: normal normal normal 14px / 21px Poppins;
  color: #979797 !important;
}

font {
  font-family: "Poppins Regular" !important;
  font: normal normal normal 14px / 21px Poppins;
  color: #979797;
}

.text3 {
  font: normal normal medium 14px/21px Poppins;
  letter-spacing: 0px;
  color: #979797;
  opacity: 1;
}

#descriptionData p {
  /* font-family: "Poppins Regular" !important;
  font-size: 16px; */
  font-family: "Poppins Regular" !important;
  font: normal normal normal 14px/21px Poppins;
  color: #979797 !important;
  word-break: break-word;
  margin-bottom: 0px !important;
}

#descriptionReview p {
  font: italic normal normal 14px/21px Poppins;
  letter-spacing: 0px;
  color: #979797;
  word-break: break-word;
}

::placeholder {
  text-align: left;
  margin-left: 0px !important;
}

.form-control:focus {
  box-shadow: none;
}

.backArrow {
  color: #21287f;
  margin-right: 10px;
  font-size: 21px;
  cursor: pointer;
}

#ngselecetvalue span.ng-value-label.ng-star-inserted {
  color: black;
}

#descriptionData div span {
  /* font-family: "Poppins Medium";
  font-size: 16px; */
  font-family: "Poppins Regular" !important;
  font: normal normal normal 14px/21px Poppins;
}

#descriptionData div {
  /* font-family: "Poppins Medium";
  font-size: 16px; */
  font-family: "Poppins Regular" !important;
  font: normal normal normal 14px/21px Poppins;
}

.angular-editor-toolbar {
  display: none;
}

.angular-editor .angular-editor-wrapper .angular-editor-textarea {
  min-height: 6.5rem;
  height: 6.5rem !important;
  padding: 0px;
  border: none;
  border-bottom: 2px solid #97979785;
  background-color: transparent;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
}

.angular-editor .angular-editor-wrapper .angular-editor-placeholder {
  position: absolute;
  top: 0;
  padding: 0px;
  color: #6c757d;
  opacity: 0.75;
}

select {
  padding: 9px;
  border-radius: 3px;
  margin-right: 3px;
  box-shadow: 0 1px 3px -2px #9098a9;
  cursor: pointer;
  font-family: inherit;
  font-size: 16px;
  transition: all 150ms ease;
}

option {
  font-weight: normal;
  display: block;
  white-space: nowrap;
  min-height: 1.2em;
  padding: 0px 2px 1px;
}

::-webkit-scrollbar {
  width: 7px;
}

/* Track */

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: grey;
}

.LyButton--icon-appearance-s,
.LyButton--medium-size-y {
  border-radius: 0px !important;
  background-color: #00000059 !important;
  color: white !important;
  min-height: 40px !important;
}
